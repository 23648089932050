<template>
  <div class="service-card-container">
    <CardService
      v-for="service in serviceList"
      :key="service.id"
      class="service-card"
      :service="service"
      :service-parent-type-id="serviceParentTypeId"
      :show-highlight-tag="showHighlightTag"
      @click="clickCardServiceHandler"
    />
  </div>
</template>

<script>
import CardService from '@/components/Card/CardService'
export default {
  components: {
    CardService
  },
  props: {
    serviceList: {
      type: Array,
      default: null
    },
    serviceParentTypeId: String | Number,
    showHighlightTag: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    activeRouteName () {
      return this.$nuxt.$route.name
    }
  },
  methods: {
    clickCardServiceHandler (service) {
      const pageName = activeRouteName === 'home' ? '首頁' : '分類頁'
      this.$gaLogEvent(pageName, `click_${service.title}_${service.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$cardMargin: 24px;
.service-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(38px - #{$cardMargin});
  align-items: stretch;
  .service-card {
    width: calc((100% - #{$cardMargin} * 3) / 4);
    max-width: 263px;
    margin-bottom: $cardMargin;
    height: initial;
    &:not(:nth-child(4n)) {
      margin-right: $cardMargin;
    }
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .service-card-container {
    .service-card {
      $cardMargin: 16px;
      width: calc((100% - #{$cardMargin}) / 2);
      margin-bottom: $cardMargin;
      &:not(:nth-child(4n)) {
        margin-right: 0;
      }
      &:not(:nth-child(2n)) {
        margin-right: $cardMargin;
      }
    }
  }
}
</style>
