<template>
  <div class="component-section__title">
    <div class="component-section__title__line--left" />
    <h2 class="component-section__title__text">
      {{ title }}
    </h2>
    <div class="component-section__title__line--right" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.component-section__title {
  @include line-through-title;
}
</style>
